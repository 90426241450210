import { useRouter } from 'next/router'
import { rem } from 'polished'
import React, { useState } from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Callout, CALLOUT_INTENT } from 'components/Callout'
import Icon from 'components/Icon'
import { Loader, LOADER_SIZE } from 'components/Loader'
import { Modal } from 'components/Modal'
import { Toast } from 'components/Toast'
import { Text } from 'components/Typography'
import { ROUTE } from 'constants/routes'
import { useUserAuth } from 'providers/userAuth'
import { FONT_SIZE, FONT_WEIGHT, MODAL_MAX_WIDTH } from 'Theme'
import { useMedia } from 'useMedia'
import { formatUserNameOrEmail } from 'utils/formatters'
import {
  useAdminAccessTokenMutation,
  useSendOnboardingEmailToExpertUserMutation,
} from './hooks'

const LinkText = styled(Text)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const SIGNED_IN_AS_USER_HEADER_DESKTOP_HEIGHT = rem(96)

export const SignedInAsUserHeader = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [getAdminAccessToken] = useAdminAccessTokenMutation()
  const [
    sendOnboardingEmailToExpert,
    { loading: isSendOnboardingEmailLoading },
  ] = useSendOnboardingEmailToExpertUserMutation()
  const router = useRouter()
  const media = useMedia()
  const { user, setIsPageLoading, signInWithCustomToken, signedInUserType } =
    useUserAuth()

  const mobileFontSize = {
    ...(media.MOBILE ? { fontSize: FONT_SIZE.PX_12 } : {}),
  }

  return (
    <Callout
      data-test-id={DATA_TEST_ID.ADMIN_USER_EDIT_BANNER}
      icon={Icon.User}
      intent={CALLOUT_INTENT.PRIMARY}
      title={`You're Administrator currently signed in as ${formatUserNameOrEmail(
        { user, signedInUserType }
      )}`}
      height={{
        MOBILE: 'auto',
        DESKTOP: SIGNED_IN_AS_USER_HEADER_DESKTOP_HEIGHT,
      }}
    >
      <Modal
        maxWidth={MODAL_MAX_WIDTH.PX_600}
        ariaLabel="Email confirmation"
        onModalClose={() => {
          setIsModalOpen(false)
        }}
        title="Email confirmation"
        confirmButtonText="Send email"
        isOpen={isModalOpen}
        onConfirm={async () => {
          const response = await sendOnboardingEmailToExpert()
          if (!response.errors) {
            Toast.success('Success! Onboarding email was successfully sent.')
            setIsModalOpen(false)
          }
        }}
        isLoading={isSendOnboardingEmailLoading}
      >
        <Text>
          Are you sure you want to send onboarding email to{' '}
          <Text fontWeight={FONT_WEIGHT.MEDIUM}>{user?.email}</Text>?
        </Text>
      </Modal>
      {isLoading ? (
        <Loader size={LOADER_SIZE.XXS} />
      ) : (
        <>
          <Text {...mobileFontSize}>
            Go back to{' '}
            <LinkText
              {...mobileFontSize}
              onClick={async () => {
                setIsPageLoading(true)
                setIsLoading(true)
                try {
                  const {
                    data: { exchangeCustomTokenForAdminToken: adminToken },
                  } = await getAdminAccessToken()
                  await signInWithCustomToken({ token: adminToken })
                  router.push(ROUTE.ADMIN())
                } catch (error) {
                  Toast.error(error.message)
                  setIsPageLoading(false)
                  setIsLoading(false)
                }
              }}
              fontWeight={FONT_WEIGHT.MEDIUM}
              aria-label="Admin dashboard"
            >
              Admin dashboard.
            </LinkText>
          </Text>
          {user?.onboardingToken && (
            <Text {...mobileFontSize}>
              This account is not yet onboarded.{' '}
              <LinkText
                {...mobileFontSize}
                onClick={() => {
                  setIsModalOpen(true)
                }}
                fontWeight={FONT_WEIGHT.MEDIUM}
                aria-label="Click here to send onboarding email"
              >
                Click here
              </LinkText>{' '}
              to send onboarding email.
            </Text>
          )}
        </>
      )}
    </Callout>
  )
}
