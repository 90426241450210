import { DialogOverlay, type DialogOverlayProps } from '@reach/dialog'
import { transparentize } from 'polished'
import React, { useRef } from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { SPACE, COLOR, Z_INDEX } from 'Theme'
import { useMedia } from 'useMedia'
import { ModalContent, type ModalContentProps } from './ModalContent'

export const Overlay = styled(DialogOverlay).withConfig({
  shouldForwardProp: (prop) => !['isMobile'].includes(prop),
})`
  overflow: hidden;
  z-index: ${Z_INDEX.OVERLAY};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  justify-items: center;
  align-content: center;
  background-color: ${transparentize(0.4, COLOR.GRAYSCALE_1)};
  ${({ isMobile }) => isMobile && `padding: ${SPACE.PX_5}`}
`

export interface ModalProps extends ModalContentProps {
  isOpen: boolean
  onDismiss?: Pick<DialogOverlayProps, 'onDismiss'>['onDismiss']
  onModalClose: () => void
}

export const Modal: React.FC<React.PWC<ModalProps>> = ({
  children,
  isOpen,
  onModalClose,
  onDismiss = onModalClose,
  ...rest
}) => {
  const { MOBILE: isMobile } = useMedia()
  const contentRef = useRef(null)
  return (
    <Overlay
      isMobile={isMobile}
      isOpen={isOpen}
      onDismiss={onDismiss}
      initialFocusRef={contentRef}
      data-test-id={DATA_TEST_ID.MODAL_OVERLAY}
    >
      <ModalContent isOpen={isOpen} onModalClose={onModalClose} {...rest}>
        {children}
      </ModalContent>
    </Overlay>
  )
}
