import { isBoolean } from 'lodash'
import React, { type ReactNode } from 'react'
import styled from 'styled-components'
import {
  type LayoutProps,
  type OrderProps,
  type SpaceProps,
} from 'styled-system'
import { Box } from 'components/Layout'

type ReactNodeOrBoolean = ReactNode | boolean
interface MediaQueryWrapperProps
  extends Omit<LayoutProps, 'display'>,
    OrderProps,
    SpaceProps {
  mobile?: ReactNodeOrBoolean
  tablet?: ReactNodeOrBoolean
  desktop?: ReactNodeOrBoolean
  tv?: ReactNodeOrBoolean
}

const findNode = <T,>(array: T[]) => array.find((item) => !isBoolean(item))

const StyledBox = styled(Box)`
  @media print {
    display: none;
  }
`

/**
 * @description Container for conditional rendering of content to prevent layout changes on load due to SSR.
 * Utilizes mobile first approach, meaning, that lower breakpoints will be rendered all the way up
 * until a specified higher breakpoint is found. Use `false` to skip breakpoint and `null` to not render anything.
 * @param mobile - React node to render from mobile upwards.
 * @param tablet - React node to render from tablet upwards.
 * @param desktop - React node to render from desktop upwards.
 * @param tv - React node to render from tv upwards.
 * @returns Set of Box components with computed display properties based on input.
 */
export const MediaQueryWrapper: React.FC<React.PWC<MediaQueryWrapperProps>> = ({
  mobile = true,
  tablet = true,
  desktop = true,
  tv = true,
  ...wrapperProps
}) => (
  <>
    {mobile && (
      <StyledBox
        height="inherit"
        display={{ MOBILE: 'block', TABLET: 'none' }}
        {...wrapperProps}
      >
        {mobile}
      </StyledBox>
    )}
    {tablet && (
      <StyledBox
        height="inherit"
        display={{ MOBILE: 'none', TABLET: 'block', DESKTOP: 'none' }}
        {...wrapperProps}
      >
        {findNode([tablet, mobile])}
      </StyledBox>
    )}
    {desktop && (
      <StyledBox
        height="inherit"
        display={{
          MOBILE: 'none',
          DESKTOP: 'block',
          TV: 'none',
        }}
        {...wrapperProps}
      >
        {findNode([desktop, tablet, mobile])}
      </StyledBox>
    )}
    {tv && (
      <Box
        height="inherit"
        display={{
          MOBILE: 'none',
          TV: 'block',
        }}
        {...wrapperProps}
      >
        {findNode([tv, desktop, tablet, mobile])}
      </Box>
    )}
  </>
)
