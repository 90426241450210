import { useMutation, gql } from '@apollo/client'

const SEND_ONBOARDING_EMAIL_TO_EXPERT_USER_MUTATION = gql`
  mutation sendOnboardingEmailToExpert {
    sendOnboardingEmailToExpert
  }
`

export const useSendOnboardingEmailToExpertUserMutation = (options) =>
  useMutation(SEND_ONBOARDING_EMAIL_TO_EXPERT_USER_MUTATION, options)
