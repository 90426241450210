import PropTypes from 'prop-types'
import React from 'react'
import { COLOR } from 'Theme'

const Logo = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 160 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-labelledby="title"
  >
    <title id="title">The Expert Logo</title>
    <path
      d="M31.7095 41.2854L34.5362 30.8134L32.3522 30.7307L32.6589 29.8054L34.8722 29.4987L35.8509 25.9333H38.0909L37.1122 29.6667H39.7789L39.8082 30.7573H36.8002L33.8669 40.624L35.5735 40.8213L37.5069 38.3014L37.7575 38.496C36.6109 40.896 35.1815 42.1653 34.3149 42.1653C33.7522 42.1547 31.6242 41.6214 31.7095 41.2854Z"
      fill={color}
    />
    <path
      d="M45.3997 41.1466C45.3997 40.6986 46.4664 37.9466 47.2477 35.7973C47.8077 34.2586 48.5624 32.4666 48.5624 31.9333C48.5624 31.3999 47.8904 30.8666 47.2744 30.8666C46.4344 30.8666 44.4744 33.7226 42.0664 38.8479L41.0877 41.8986L38.8184 41.7599C38.9037 41.5626 39.8557 38.4826 40.2744 37.3066C41.3677 33.9199 43.6077 24.9333 43.6077 24.9333C43.6904 24.6239 43.6637 24.3173 43.4664 24.2879L41.3677 24.0213L41.4504 23.6853L46.3517 22.9573L45.5117 26.1786C44.1944 30.6853 42.9357 35.4159 42.0957 37.4053L42.2904 37.4319C43.9144 34.1279 45.5677 31.3573 46.557 30.3759C47.1083 29.7911 47.8572 29.4319 48.6584 29.3679C49.693 29.3679 50.7304 30.1253 50.7304 31.0213C50.7304 31.8613 50.1144 33.7653 49.397 35.7253C48.781 37.4053 47.661 40.3439 47.5757 40.6239L48.837 40.8213L50.797 38.3013L51.0477 38.4959C49.901 40.8959 48.3597 42.1653 47.5197 42.1653C46.933 42.1546 45.3997 41.5386 45.3997 41.1466Z"
      fill={color}
    />
    <path
      d="M52.6318 38.1226C52.6318 34.0053 56.0745 29.3013 59.4905 29.3013C61.2825 29.3013 62.4585 30.2266 62.4585 31.5973C62.4585 33.4186 60.2185 34.4266 55.2345 35.8266C55.0837 36.4379 54.9996 37.0638 54.9838 37.6933C54.9838 39.6266 55.7838 40.6266 57.2798 40.6266C58.4825 40.6266 59.3785 39.9813 60.8345 38.1066L61.1012 38.3013C59.8398 40.2906 58.1892 42.1386 56.1998 42.1386C54.0878 42.1546 52.6318 40.3066 52.6318 38.1226ZM60.3652 31.3733C60.3638 31.0024 60.2158 30.6471 59.9536 30.3849C59.6913 30.1226 59.336 29.9747 58.9652 29.9733C58.4318 29.9733 57.9838 30.2559 57.5358 30.6746C56.7358 31.4746 55.7998 33.3413 55.2958 35.4053L55.3518 35.4346C59.1545 34.2293 60.3598 33.1946 60.3598 31.3733H60.3652Z"
      fill={color}
    />
    <path
      d="M66.6318 36.0507C66.6318 32.2134 69.3465 29.2454 73.0318 29.2454C75.9438 29.2454 77.6532 30.9814 77.6532 33.2454C77.6509 33.5089 77.6223 33.7715 77.5678 34.0294C77.5678 34.0294 69.9252 33.944 69.6452 33.944C69.5879 34.3056 69.5594 34.6712 69.5598 35.0374C69.5892 38.1734 71.3518 40.272 73.8265 40.272C75.3118 40.272 76.5145 39.7387 77.4665 38.2294L77.7758 38.3974C76.5998 40.7974 74.8425 42.2054 72.3705 42.2054C69.0665 42.2 66.6318 39.5494 66.6318 36.0507ZM74.8078 33.4454C74.8345 31.5414 74.2185 29.8054 72.6745 29.8054C71.2452 29.8054 69.9865 31.3734 69.6772 33.4747L74.8078 33.4454Z"
      fill={color}
    />
    <path
      d="M78.5121 41.3707L79.2108 41.2587C80.2188 41.0907 80.6961 40.6134 82.2348 38.6534L84.0268 36.3014L81.1441 32.1574C80.1921 30.7867 79.7441 30.2907 78.9868 30.1974L78.5388 30.1147L78.4561 29.6934H84.9788L84.8961 30.1147L84.3361 30.1974C83.4401 30.2827 83.5228 30.8134 84.3068 31.9627L85.9308 34.3974H85.9601L87.2748 32.5307C88.5068 30.7947 88.4801 30.3174 87.2188 30.2054L86.6588 30.1227L86.5761 29.7014H91.5868L91.5041 30.1227L90.8028 30.2347C89.8241 30.3734 89.2641 30.8507 87.7788 32.8667L86.2668 34.7974L89.4028 39.3307C90.3548 40.648 90.8588 41.1974 91.5361 41.2907L91.9574 41.3467L92.0694 41.7947H85.5681L85.6508 41.376L86.1547 41.2907C87.0801 41.1787 87.0241 40.6747 86.2401 39.528L84.3734 36.728H84.3174L82.7494 38.968C81.4908 40.704 81.4908 41.208 82.7228 41.2907L83.3654 41.376L83.4214 41.7947H78.4268L78.5121 41.3707Z"
      fill={color}
    />
    <path
      d="M92.8004 48.3706L93.3898 48.3413C94.1738 48.2853 94.5098 48.2026 94.5098 47.8933V33.2266C94.5098 31.52 94.2858 31.24 93.3338 31.016L92.7178 30.8746L92.7444 30.5386L97.3098 29.3066L97.2538 31.7706L97.3658 31.8C98.4858 30.4666 99.9418 29.4186 101.632 29.4186C104.486 29.4186 106.614 31.9386 106.614 35.3546C106.614 39.6666 103.366 42.1866 100.064 42.1866C99.1204 42.2027 98.183 42.0257 97.3098 41.6666H97.2804V47.9386C97.2804 48.1626 97.4218 48.2746 98.5684 48.3306L99.5471 48.36L99.6324 48.808H92.7178L92.8004 48.3706ZM103.579 36.2453C103.579 33.3893 102.179 31.2346 100.024 31.2346C99.4999 31.2461 98.9838 31.3693 98.5106 31.596C98.0375 31.8227 97.6181 32.1477 97.2804 32.5493V39.1306C98.0084 40.5013 99.2404 41.5946 100.528 41.5946C102.376 41.5946 103.579 39.2426 103.579 36.2453Z"
      fill={color}
    />
    <path
      d="M108.288 36.0507C108.288 32.2134 111.003 29.2454 114.688 29.2454C117.6 29.2454 119.307 30.9814 119.307 33.2454C119.305 33.5088 119.278 33.7714 119.224 34.0294C119.224 34.0294 111.579 33.944 111.301 33.944C111.244 34.3056 111.216 34.6712 111.216 35.0374C111.245 38.1734 113.008 40.272 115.483 40.272C116.965 40.272 118.171 39.7387 119.123 38.2294L119.429 38.3974C118.253 40.7974 116.496 42.2054 114.027 42.2054C110.723 42.2 108.288 39.5494 108.288 36.0507ZM116.461 33.4454C116.491 31.5414 115.875 29.8054 114.328 29.8054C112.901 29.8054 111.64 31.3734 111.333 33.4747L116.461 33.4454Z"
      fill={color}
    />
    <path
      d="M120.771 41.3413L121.304 41.3147C122.104 41.2587 122.424 41.1733 122.424 40.8373V33.2507C122.424 31.5147 122.2 31.2347 121.248 31.0107L120.603 30.8693L120.632 30.5333L125.224 29.3013L125.168 33.4747H125.251C126.203 30.7867 127.323 29.2747 129.003 29.2747C130.069 29.2747 130.907 30.0027 130.907 31.0107C130.913 31.225 130.877 31.4385 130.8 31.6385C130.722 31.8385 130.605 32.0209 130.456 32.1749C130.307 32.3289 130.128 32.4513 129.931 32.5349C129.733 32.6184 129.521 32.6614 129.307 32.6613C128.871 32.6623 128.451 32.503 128.125 32.2138C127.799 31.9247 127.592 31.5258 127.541 31.0933C126.619 31.3733 125.861 32.5227 125.163 34.7067V40.8933C125.163 41.16 125.387 41.2587 126.496 41.3147L127.336 41.3413L127.419 41.7893H120.672L120.771 41.3413Z"
      fill={color}
    />
    <path
      d="M134.114 38.7093L134.256 31.1227L132.016 31.0373L132.266 30.3387L134.032 29.0053L136.608 26.9333L137.026 27.0187L137 29.704H141.312L140.92 31H137L136.832 38.28C136.802 39.8187 137.504 40.52 138.93 40.52C139.775 40.5179 140.594 40.2314 141.256 39.7067L141.394 39.9867C140.248 41.192 138.818 42.144 137.306 42.144C135.234 42.1547 134.088 41.0907 134.114 38.7093Z"
      fill={color}
    />
    <path d="M20.1172 16.7119H20.9172V56.2372H20.1172V16.7119Z" fill={color} />
  </svg>
)

Logo.defaultProps = {
  width: '160px',
  height: '80px',
  color: COLOR.BLACK,
}

Logo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.oneOf(Object.values(COLOR)),
}

export default Logo
