import { DialogContent, type DialogContentProps } from '@reach/dialog'
import React, { useEffect, useRef } from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import styled, { css } from 'styled-components'
import { type LayoutProps } from 'styled-system'
import { Button, BUTTON_VARIANT } from 'components/Button'
import { Delimiter } from 'components/Delimiter'
import Icon from 'components/Icon'
import { Box, Flex } from 'components/Layout'
import { H5 } from 'components/Typography'
import { RADIUS, SPACE, BOX_SHADOW, COLOR, COLOR_INTENT, Z_INDEX } from 'Theme'
import { useMedia } from 'useMedia'

const Content = styled(DialogContent).withConfig({
  shouldForwardProp: (prop) => !['maxWidth', 'isMobile'].includes(prop),
})`
  overflow: ${({ overflow }) => overflow || 'auto'};
  position: relative;
  max-height: 100%;
  ${({ maxHeight }) =>
    maxHeight
      ? `max-height: ${maxHeight}`
      : `
          max-height: 100%;
          max-height: -webkit-fill-available;
        `};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
  width: ${({ maxWidth }) => (maxWidth ? '100%' : 'auto')};
  box-shadow: ${BOX_SHADOW.ELEVATION_6};
  background-color: ${COLOR.WHITE};
  border-radius: ${RADIUS.PX_4};
  outline: none;
  ${({ isMobile, isAttachedToBottom }) =>
    isMobile &&
    css`
      min-width: 0;
      overflow: auto;
      max-height: 100%;
      width: 100%;
      ${isAttachedToBottom &&
      css`
        position: absolute;
        bottom: -40px;
        padding-bottom: 40px;
        border-radius: ${RADIUS.PX_10} ${RADIUS.PX_10} 0 0;
      `}
    `}
`

const IconWrapper = styled(Box)`
  &:hover {
    cursor: pointer;
  }
`

const getPaddingX = (isMobile: boolean) =>
  isMobile ? SPACE.PX_15 : SPACE.PX_30

export interface ModalContentProps
  extends Omit<DialogContentProps, 'title'>,
    LayoutProps {
  customFooter?: () => React.ReactNode
  customTitle?: () => React.ReactNode
  title?: React.ReactNode
  isOpen: boolean
  onModalClose: () => void
  ariaLabel: string
  confirmButtonText?: string
  confirmButtonProps?: React.ComponentProps<typeof Button>
  cancelButtonText?: string
  onConfirm?: () => void
  isWithoutPaddings?: boolean
  isWithFooter?: boolean
  maxWidth?: string
  isLoading?: boolean
  isConfirmDisabled?: boolean
  isWithHeaderDelimiter?: boolean
  isWithFooterDelimiter?: boolean
  isHeaderSticky?: boolean
  isFooterSticky?: boolean
  isAttachedToBottom?: boolean
  isMobile?: boolean
  isCancelButtonVisible?: boolean
  isWithCloseIcon?: boolean
}

export const ModalContent: React.FC<React.PWC<ModalContentProps>> = ({
  children,
  isOpen,
  onModalClose,
  ariaLabel,
  title,
  customTitle,
  confirmButtonText,
  confirmButtonProps = {},
  cancelButtonText = 'Cancel',
  onConfirm,
  customFooter,
  isWithoutPaddings,
  isWithFooter = true,
  maxWidth,
  isLoading,
  isConfirmDisabled,
  isWithHeaderDelimiter = true,
  isWithFooterDelimiter = true,
  isHeaderSticky,
  isCancelButtonVisible = true,
  isWithCloseIcon = true,
  isFooterSticky = false,
  ...rest
}) => {
  const { MOBILE: isMobile } = useMedia()
  const contentRef = useRef(null)

  useEffect(() => {
    if (isOpen) {
      // disable body scrolling when the modal is opened
      document.body.style.overflow = 'hidden'
    }

    return () => {
      // called with not changed state value before use effect with changed value
      if (isOpen) {
        // enable body scrolling again when the modal is closed
        document.body.style.overflow = ''
      }
    }
  }, [isOpen])
  return (
    <Content
      maxWidth={maxWidth}
      isMobile={isMobile}
      aria-label={ariaLabel}
      ref={contentRef}
      {...rest}
    >
      <Box
        position={isHeaderSticky ? 'sticky' : undefined}
        top={isHeaderSticky && 0}
        backgroundColor={COLOR_INTENT.MODAL.BACKGROUND}
        zIndex={Z_INDEX.MODAL_HEADER}
      >
        <Flex
          justifyContent="space-between"
          px={getPaddingX(isMobile)}
          py={SPACE.PX_20}
        >
          {customTitle ? customTitle() : <H5 mb={0}>{title}</H5>}
          {isWithCloseIcon && (
            <IconWrapper
              onClick={onModalClose}
              data-test-id={DATA_TEST_ID.MODAL_CLOSE_ICON}
            >
              <Icon.Cross color={COLOR.GRAYSCALE_3} />
            </IconWrapper>
          )}
        </Flex>
        {isWithHeaderDelimiter && <Delimiter />}
      </Box>
      <Box
        px={isWithoutPaddings ? 0 : getPaddingX(isMobile)}
        py={isWithoutPaddings ? 0 : SPACE.PX_20}
      >
        {children}
      </Box>
      {customFooter ? (
        <Box
          position={{
            MOBILE: 'sticky',
            TABLET: isFooterSticky ? 'sticky' : 'static',
          }}
          bottom={0}
          backgroundColor={COLOR_INTENT.MODAL.BACKGROUND}
        >
          {isWithFooterDelimiter && <Delimiter />}
          {customFooter()}
        </Box>
      ) : (
        <>
          {isWithFooter && (
            <Box
              position={{
                MOBILE: 'sticky',
                TABLET: isFooterSticky ? 'sticky' : 'static',
              }}
              bottom={0}
              backgroundColor={COLOR_INTENT.MODAL.BACKGROUND}
            >
              {isWithFooterDelimiter && <Delimiter />}
              <Flex
                px={getPaddingX(isMobile)}
                py={SPACE.PX_20}
                justifyContent="flex-end"
              >
                {isCancelButtonVisible && (
                  <Box>
                    <Button
                      onClick={onModalClose}
                      variant={BUTTON_VARIANT.OUTLINE}
                      isLoading={isLoading}
                      data-test-id={DATA_TEST_ID.MODAL_CLOSE_BUTTON}
                    >
                      {cancelButtonText}
                    </Button>
                  </Box>
                )}
                {confirmButtonText && (
                  <Box ml={SPACE.PX_10}>
                    <Button
                      onClick={onConfirm}
                      isLoading={isLoading}
                      isDisabled={isConfirmDisabled}
                      data-test-id={DATA_TEST_ID.MODAL_CONFIRM_BUTTON}
                      {...confirmButtonProps}
                    >
                      {confirmButtonText}
                    </Button>
                  </Box>
                )}
              </Flex>
            </Box>
          )}
        </>
      )}
    </Content>
  )
}
